<template>
  <div class="mx-8">
    <v-row>
      <v-col cols="12" md="4">
        <v-img src="@/assets/230.png"></v-img>
      </v-col>
      <v-col cols="12" md="8">
        <v-card-text>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                color="error"
                @click="showDialogEdit()"
                >mdi-pencil-box</v-icon
              >
            </template>
            <span>Edit Data</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                color="error"
                @click="dialogDelete = true"
                >mdi-delete</v-icon
              >
            </template>
            <span>Hapus Data</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                color="error"
                @click="dialogReset = true"
                >mdi-lock-reset</v-icon
              >
            </template>
            <span>Reset Password</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="error"
                @click="showDialogAddGroup()"
                >mdi-bookmark-plus</v-icon
              >
            </template>
            <span>Tambah Group</span>
          </v-tooltip>
        </v-card-text>
        <v-list dense class="py-0">
          <v-list-item>
            <v-list-item-content>username</v-list-item-content>
            <v-list-item-content class="align-end">{{
              detailUserAdmin.username
            }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Nama</v-list-item-content>
            <v-list-item-content class="align-end">{{
              detailUserAdmin.nama
            }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Status</v-list-item-content>
            <v-list-item-content class="align-end"
              ><span v-if="detailUserAdmin.status == 'true'">
                <v-chip class="success" small>AKTIF</v-chip></span
              ><span v-else>
                <v-chip class="error" small>TIDAK AKTIF</v-chip></span
              >
            </v-list-item-content>
          </v-list-item>

          <span v-for="(group, index) in detailUserAdmin.group" :key="index">
            <v-chip
              class="ma-2"
              close
              color="teal"
              text-color="white"
              close-icon="mdi-delete"
              @click:close="showDialogDeleteGroup(group)"
            >
              {{ group }}
            </v-chip>
          </span>
        </v-list>
      </v-col>
    </v-row>

    <v-card outlined class="mb-8">
      <v-data-table :headers="headers" :items="detailUserAdmin.akses">
        <template v-slot:item.nomor="{ item }">
          <span>{{ detailUserAdmin.akses.indexOf(item) + 1 }}</span>
        </template>

        <template v-slot:item.akses="{ item }">
          <span>{{ item._id }} - {{ item.akses }}</span>
        </template>

        <template v-slot:item.method="{ item }">
          <span>{{ item.method | replaceString }}</span>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogEdit" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Edit Data
        </v-card-title>

        <br />

        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="username"
                  v-model="dataEdit.username"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field
                  label="Nama"
                  v-model="dataEdit.nama"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-select
                  label="Status"
                  v-model="dataEdit.status"
                  :items="statusOptions"
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" md="12">
                <v-select
                  label="Wilayah"
                  v-model="dataEdit.wilayah"
                  :items="wilayahOptions"
                  @change="getListSatker(dataEdit.wilayah)"
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" md="12">
                <v-select
                  label="Satuan Kerja"
                  v-model="dataEdit.satker"
                  :items="satkerOptions"
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="editData()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogReset" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Reset Password
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin melakukan reset password username
          <strong> {{ detailUserAdmin.username }} </strong> ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogReset = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="resetPassword()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAddGroup" width="700">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Tambah Group
        </v-card-title>

        <br />

        <v-card-text>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
                v-for="(group, index) in listDataGroup"
                :key="index"
              >
                <v-checkbox
                  v-model="dataGroup.group"
                  :label="group.subgroup"
                  :value="group._id"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogAddGroup = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="addGroup()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteGroup" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Hapus Group
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin menghapus group
          <strong> {{ dataGroup.group }} </strong> ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDeleteGroup = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="deleteGroup()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Hapus Data
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin menghapus seluruh data username
          <strong> {{ detailUserAdmin.username }} </strong> ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="deleteData()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="success" timeout="1000">
      {{ snackbarText }}

      <template v-slot:action>
        <v-btn fab x-small color="white" @click="snackbar = false">
          <v-icon color="error">mdi-close-thick</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      headers: [
        { text: "No", value: "nomor", width: "70px", sortable: false },
        { text: "Akses", value: "akses" },
        { text: "Metode", value: "method", sortable: false },
      ],
      dialogEdit: false,
      dialogDelete: false,
      dialogReset: false,
      dialogAddGroup: false,
      dialogDeleteGroup: false,
      dataEdit: {
        username: "",
        nama: "",
        satker: "",
        status: "",
      },
      dataGroup: {
        group: [],
      },
      wilayahOptions: [],
      satkerOptions: [],
      statusOptions: [
        { text: "AKTIF", value: "true" },
        { text: "TIDAK AKTIF", value: "false" },
      ],
      snackbar: false,
      snackbarText: "",
    };
  },
  computed: {
    detailUserAdmin() {
      return this.$store.getters.detailUserAdmin;
    },
    listDataGroup() {
      return this.$store.getters.listDataGroup;
    },
  },
  created() {
    this.init();
    this.$store.dispatch("getListDataWilayah").then((res) => {
      res.data.forEach((element) => {
        if (element.isEnable == true) {
          this.wilayahOptions.push({ text: element.label, value: element._id });
        }
      });
    });
    this.$store.dispatch("getListDataGroup");
  },
  methods: {
    init() {
      this.$store.dispatch("getDetailUserAdmin", this.id);
    },
    getListSatker(wilayah) {
      this.satkerOptions = [];

      const data = {
        kelompok: wilayah,
      };
      this.$store.dispatch("getListDataSatker", data).then((res) => {
        res.data.forEach((element) => {
          if (element.isEnable == true) {
            this.satkerOptions.push({
              text: element.label,
              value: element._id,
            });
          }
        });
      });
    },
    showDialogEdit() {
      this.dataEdit.username = this.detailUserAdmin.username;
      this.dataEdit.nama = this.detailUserAdmin.nama;
      this.dataEdit.status = this.detailUserAdmin.status;
      this.dialogEdit = true;
    },
    editData() {
      const data = {
        id: this.id,
        username: this.dataEdit.username,
        nama: this.dataEdit.nama,
        satker: this.dataEdit.satker,
        status: this.dataEdit.status,
      };
      this.$store.dispatch("editUserAdmin", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogEdit = false;
        }
      });
    },
    resetPassword() {
      const data = {
        id: this.id,
      };
      this.$store.dispatch("resetUserAdmin", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        this.dialogReset = false;
      });
    },
    showDialogAddGroup() {
      this.dataGroup.group = this.detailUserAdmin.group;
      this.dialogAddGroup = true;
    },
    addGroup() {
      const difference = this.dataGroup.group.filter(
        (element) => !this.detailUserAdmin.group.includes(element)
      );

      const data = {
        id: this.id,
        group: difference,
      };
      this.$store.dispatch("setGroupUserAdmin", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogAddGroup = false;
        }
      });
    },
    showDialogDeleteGroup(group) {
      this.dataGroup.group = group;
      this.dialogDeleteGroup = true;
    },
    deleteGroup() {
      const data = {
        id: this.id,
        group: this.dataGroup.group,
      };
      this.$store.dispatch("removeGroupUserAdmin", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogDeleteGroup = false;
        }
      });
    },
    deleteData() {
      const data = {
        id: this.id,
      };
      this.$store.dispatch("deleteUserAdmin", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        this.dialogDelete = false;
        if (value.hasil == "success") {
          this.$router.go(-1);
        }
      });
    },
  },
};
</script>